import "./Css/Access.css";

function AccessRights() {
  return (
    <div className="AccessRightWrapp">
      <h6 className="AccessTitle">
        Ви не маєте прав доступу до цієї сторінки!
      </h6>
    </div>
  );
}

export default AccessRights;
