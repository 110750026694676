import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Css/ErrorDefaultSession.css";

function ErrorDefaultSessionModal({ show, onClose }) {
  return (
    <div className="ModalErrorWrap">
      <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
        <Modal.Header className="modalHeader">
          <Modal.Title className="ModalTitle">Повідомлення!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBodyError">
          <span>
            Обраний Вами сканер не відповідає або не знайдений! Спробуйте
            повторити процедуру сканування та обрати інший сканер!
          </span>
        </Modal.Body>
        <Modal.Footer className="modalFotterError">
          <Button onClick={onClose} className="OKbtn">
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ErrorDefaultSessionModal;
