import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Css/ModalDownload.css";

function DownloadModal({ show, onClose, reload }) {
  return (
    <div className="ModalWrap">
      <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
        <Modal.Header className="modalHeader">
          <Modal.Title className="ModalTitle">
            Сервіс{" "}
            <span className="headerTittle" onClick={reload}>
              "Scanner Online"
            </span>{" "}
            не доступний!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          <span>
            Перевірте будь ласка чи запущений сервіс?! Для цього клікніть двічі
            по іконці "Scanner Online" на робочому столі та дочекайтеся запуску
            программи! Якщо Ви не маєте програмного забепечення, перейдіть за
            посиланням вказаному нижче, завантажте інсталяційний файл та
            встановіть програму на свій ПК, після чого запустіть сервіс з
            робочого столу як описано вище!
          </span>
          <a href="#" target="_blank" className="linkDownload">
            Завантажити "Scanner Online"
          </a>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DownloadModal;
