import axios from "axios";
import { jsPDF } from "jspdf";
import { jwtDecode } from "jwt-decode";

function Query() {
  var Reload = async () => {
    try {
      var reloadResponse = await axios.get(
        `${process.env.REACT_APP_LOCALHOST_URL}${process.env.REACT_APP_PORT}/reloadserv/`
      );
      console.log("Reload Service........", reloadResponse);
      if (reloadResponse.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch {
      console.log("Reload Service Error!");
      return false;
    }
  };

  var serviceOnline = async () => {
    try {
      var onlineResponse = await axios.get(
        `${process.env.REACT_APP_LOCALHOST_URL}${process.env.REACT_APP_PORT}/servonline/`
      );
      // console.log("Online Service........", onlineResponse);
      if (onlineResponse.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch {
      console.log("Offline Service........");
      return false;
    }
  };

  var GetScan = async (scanner) => {
    const scanData = { data: scanner };
    try {
      var response = await axios.post(
        `${process.env.REACT_APP_LOCALHOST_URL}${process.env.REACT_APP_PORT}/scan/`,
        scanData
      );
      // console.log("RESPONSE-GetScan()........ ", response);

      if (response.status === 200 && response.data.Data.status === 200) {
        // console.log("RESPONSE-GetScan() СТАТУС........ ", response);
        var blob = await GetImage();
        return blob;
      } else {
        // console.log(
        //   "RESPONSE-GetScan() error........ ",
        //   response.data.Data.text
        // );
        return response.data.Data;
      }
    } catch (error) {
      console.log("GetScan error........ ", error);
    }
  };

  var GetImage = async () => {
    try {
      var response = await axios.get(
        `${process.env.REACT_APP_LOCALHOST_URL}${process.env.REACT_APP_PORT}/getimage/`
      );
      // console.log("RESPONSE-GetImage()........", response);

      if (response.status === 200) {
        const imageData = response.data.ImageBytes;
        const byteCharacters = atob(imageData); // Перетворення з base64 в рядок байтів
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers); // Масив байтів

        const blob = new Blob([byteArray], { type: "image/jpeg" }); // Створення Blob з масиву байтів
        // const imageUrl = URL.createObjectURL(blob);
        return blob;
      } else {
        // console.log("RESPONSE-GetImage() error........", response);
        var answ = {
          text: "Get image answer",
          data: false,
          status: 204,
        };
        return answ;
      }
    } catch (error) {
      console.log("GetImage error........ ", error);
    }
  };

  var ToJpeg = async (blob1, fName) => {
    if (blob1 instanceof Blob) {
      var time = await CreateDate();
      const fileName = fName + "_" + time + ".jpg";
      const file = new File([blob1], fileName, { type: "image/jpeg" });
      // console.log("FILE-GetScan()........", file);
      return file;
    } else {
      console.error("Input is not a Blob");
      return null;
    }
  };

  var ToPdf = async (blob2, fName) => {
    try {
      let pdfFiles;

      if (!(blob2 instanceof Array)) {
        // Обробка випадку, коли blob2 є одиночним Blob
        pdfFiles = await new Promise((resolve, reject) => {
          if (blob2 instanceof Blob) {
            const imageUrl = URL.createObjectURL(blob2);
            const img = new Image();
            img.src = imageUrl;

            img.onload = async () => {
              const pdf = new jsPDF();
              const imgWidth = 210;
              const imgHeight = (img.height * imgWidth) / img.width;

              pdf.addImage(img, "JPEG", 0, 0, imgWidth, imgHeight);

              var time = await CreateDate();
              const fileName = fName + "_" + time + ".pdf";
              const pdfBlob = pdf.output("blob");
              const pdfFile = new File([pdfBlob], fileName, {
                type: "application/pdf",
              });
              //   console.log("PDF FILE........", pdfFile);
              resolve(pdfFile);
            };

            img.onerror = (error) => {
              reject(error);
            };
          } else {
            reject(new Error("Input is not a Blob"));
          }
        });
      } else if (Array.isArray(blob2)) {
        // Обробка випадку, коли blob2 є масивом Blob
        pdfFiles = await new Promise((resolve, reject) => {
          const pdf = new jsPDF();
          const imgWidth = 210;

          // Обробка кожного Blob у масиві
          const processImages = async () => {
            for (let i = 0; i < blob2.length; i++) {
              await new Promise((resolveImg, rejectImg) => {
                const imageUrl = URL.createObjectURL(blob2[i]);
                const img = new Image();
                img.src = imageUrl;

                img.onload = () => {
                  const imgHeight = (img.height * imgWidth) / img.width;
                  if (i > 0) {
                    pdf.addPage();
                  }
                  pdf.addImage(img, "JPEG", 0, 0, imgWidth, imgHeight);
                  resolveImg();
                };

                img.onerror = (error) => {
                  rejectImg(error);
                };
              });
            }

            var time = await CreateDate();
            const fileName = fName + "_" + time + ".pdf";
            const pdfBlob = pdf.output("blob");
            const pdfFile = new File([pdfBlob], fileName, {
              type: "application/pdf",
            });
            // console.log("PDF FILE ARRAY........", pdfFile);
            resolve(pdfFile);
          };

          processImages().catch(reject);
        });
      } else {
        throw new Error(
          "Invalid input: blob2 must be a Blob or an Array of Blobs"
        );
      }

      return pdfFiles;
    } catch (error) {
      console.error("Error in ToPdf:", error);
      throw error;
    }
  };

  var toB64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  var CreateDate = () => {
    return new Promise((resolve, reject) => {
      try {
        const now = new Date();

        const day = String(now.getDate()).padStart(2, "0");
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const year = now.getFullYear();

        const hours = String(now.getHours()).padStart(2, "0");
        const minutes = String(now.getMinutes()).padStart(2, "0");
        const seconds = String(now.getSeconds()).padStart(2, "0");

        const formattedDate = `${day}${month}${year}_${hours}${minutes}${seconds}`;

        resolve(formattedDate);
      } catch (error) {
        reject(error);
      }
    });
  };

  var ParseToken = async (tokUrl) => {
    if (tokUrl === null) {
      return null;
    }
    try {
      // const token =
      //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXJ2aWNlSWQiOiJkb2M1UnhnWEZqREFETlljeDNZeDBYQWx4aGEyMHQ3VXlmV05BYkZqNHRKbVNtSk44MTdMakZiR2pYWlRKdXBMIiwiZG9jdW1lbnRJZCI6MTgxMDc1MSwicGFnZSI6ImludGVybmFsIiwidXNlcklkIjo2NSwiaWF0IjoxNzI0MDc1NjU3LCJleHAiOjE3MjQ2ODA0NTd9.JeD_HgzZ7CIGi2fkcK4zcix_62TwTCY2-1xJ-gW_hkE";
      // const decoded = jwtDecode(token);
      const decoded = jwtDecode(tokUrl);
      // console.log("Decoded TOKEN ", decoded);
      return decoded;
    } catch (error) {
      // console.error("Error parsing token:", error);
      throw error;
    }
  };

  var geturl = async () => {
    const iframeUrl = window.location.href;
    // console.log("URL iframe:", iframeUrl);

    const url = new URL(iframeUrl);
    const params = new URLSearchParams(url.search);

    const tok = params.get("token");
    // const clientId = params.get("client_id");
    // const docId = params.get("doc_id");

    // var parsedTok = await ParseToken();

    // await ConfirmServiceId(parsedTok.serviceId);

    // console.log("tok з URL:", tok);
    // console.log("Client ID з URL:", clientId);
    // console.log("Doc ID з URL:", docId);
    return tok;
  };

  var ConfirmServiceId = async (id) => {
    if (id === null) {
      return false;
    }
    var servicesIdArr = await AllIdEnv();
    for (let i = 0; i < servicesIdArr.length; i++) {
      const ifFromArr = servicesIdArr[i].toString();
      // console.log("arrayId ", ifFromArr);
      if (id.serviceId.toString() === ifFromArr) {
        // console.log("url----Id ", ifFromArr);
        // console.log("Access confirmed!");
        return true;
      }
    }
    // console.log("Access failed!");
    return false;
  };

  var AllIdEnv = async () => {
    return new Promise((resolve, reject) => {
      try {
        // Отримання значень змінних середовища
        const servicesIdArray = [];
        let index = 1;

        while (true) {
          const id = process.env[`REACT_APP_SERVICES_ID_${index}`];
          if (!id) break;
          servicesIdArray.push(id.toString());
          index++;
        }

        // console.log("Services id from ENV: ", servicesIdArray);
        resolve(servicesIdArray);
      } catch (error) {
        reject(error);
      }
    });
  };

  const sendToServ = async (file, b64, Token) => {
    var ServiceId = Token.serviceId;
    var DocumentId = Token.documentId;
    var Page = Token.page;
    var UserId = Token.userId;
    var Name = file.name;
    var Type = file.type;
    var Data = b64;
    if (
      ServiceId !== undefined &&
      DocumentId !== undefined &&
      Page !== undefined &&
      UserId !== undefined &&
      Name !== undefined &&
      Type !== undefined &&
      Data !== undefined
    ) {
      // console.log("Object send serv: ", obj);
      const obj = {
        serviceId: ServiceId,
        documentId: DocumentId,
        page: Page,
        userId: UserId,
        isScan: 1,
        file: {
          name: Name,
          type: Type,
          data: Data,
        },
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_ADDRESS}/documents/addfiles`,
          obj
        );

        // console.log("Response /addfiles:", response.data);
      } catch (error) {
        console.error("Error sending request:", error);
      }
    } else {
      return;
    }
  };

  //Створити env список сервісів apiKey отримати url

  return {
    serviceOnline,
    GetScan,
    GetImage,
    ToJpeg,
    ToPdf,
    toB64,
    geturl,
    ConfirmServiceId,
    ParseToken,
    sendToServ,
    Reload,
  };
}

export default Query;
