import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Css/ErrorDefaultSession.css";

function ErrorDefaultSessionModal({ show, onClose, scanFunction }) {
  var scan = async () => {
    onClose();
    await scanFunction();
  };
  return (
    <div className="ModalErrorWrap">
      <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
        <Modal.Header className="modalHeader">
          <Modal.Title className="ModalTitle">Повідомлення!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBodyError">
          <span>
            Сканер за завмовчуванням не відповідає або не знайдений! Натисніть
            "ОК" та оберіть сканер зі списку в наступному вікні!
          </span>
        </Modal.Body>
        <Modal.Footer className="modalFotterError">
          <Button onClick={scan} className="OKbtn">
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ErrorDefaultSessionModal;
