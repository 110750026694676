import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Query from "./Components/Query";
import DownloadModal from "./Components/DownloadModal";
import WaitModal from "./Components/WaitCompleteScann";
import DefaultErrorModal from "./Components/ErrorDefaultSession";
import SelectErrorModal from "./Components/ErrorSelectSession";
import UknownErrorModal from "./Components/UknownProblemModal";
import ErrorScanner404Modal from "./Components/ErrorScanner404";

import Access from "./AccessRights";

function App() {
  const {
    serviceOnline,
    GetScan,
    ToJpeg,
    ToPdf,
    toB64,
    geturl,
    ConfirmServiceId,
    ParseToken,
    sendToServ,
    Reload,
  } = Query();

  const [clickCount, setClickCount] = useState(0);
  const [timer, setTimer] = useState(null);

  const [showLoad, setShowLoad] = useState(false);
  const [showWait, setShowWait] = useState(false);
  const [showDefaultError, setShowDefaultError] = useState(false);
  const [showSelectError, setShowSelectError] = useState(false);
  const [showUknownError, setShowUknownError] = useState(false);
  const [showScanner404Error, setShowScanner404Error] = useState(false);

  const [validated, setValidated] = useState(false);
  const [format, setFormat] = useState("JPEG");
  const [NameFile, setNameFile] = useState("Документ");
  const [isCheckedScann, setIsCheckedScann] = useState(true);
  const [isCheckedPages, setIsCheckedPages] = useState(false);
  const [fileCounter, setFileCounter] = useState(0);
  const [fileArray, setFileArray] = useState([]);
  const [fileArrayPdf, setFileArrayPdf] = useState([]);
  const [saveScannBtn, setSaveScannBtn] = useState(false);
  const [EnterAccess, setEnterAccess] = useState(false);

  useEffect(() => {
    const checkServerStatus = async () => {
      const online = await serviceOnline();
      setShowLoad(!online);
      const tok = await geturl();
      // const token =
      //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXJ2aWNlSWQiOiJkb2M1UnhnWEZqREFETlljeDNZeDBYQWx4aGEyMHQ3VXlmV05BYkZqNHRKbVNtSk44MTdMakZiR2pYWlRKdXBMIiwiZG9jdW1lbnRJZCI6MTgxMDc1MSwicGFnZSI6ImludGVybmFsIiwidXNlcklkIjo2NSwiaWF0IjoxNzI0MDc1NjU3LCJleHAiOjE3MjQ2ODA0NTd9.JeD_HgzZ7CIGi2fkcK4zcix_62TwTCY2-1xJ-gW_hkE";
      var parsedTok = await ParseToken(tok);
      var confirm = await ConfirmServiceId(parsedTok);
      setEnterAccess(confirm);
      // console.log("confirm=========== ", parsedTok);
    };

    checkServerStatus();
    if (format === "JPEG") {
      setIsCheckedPages(false);
    }
  }, [serviceOnline]);

  useEffect(() => {
    if (saveScannBtn) {
      toPdfArray();
    }
  }, [saveScannBtn]);

  var info = async () => {
    try {
      var tok = await geturl();
      // const token =
      //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXJ2aWNlSWQiOiJkb2M1UnhnWEZqREFETlljeDNZeDBYQWx4aGEyMHQ3VXlmV05BYkZqNHRKbVNtSk44MTdMakZiR2pYWlRKdXBMIiwiZG9jdW1lbnRJZCI6MTgxMDc1MSwicGFnZSI6ImludGVybmFsIiwidXNlcklkIjo2NSwiaWF0IjoxNzI0MDc1NjU3LCJleHAiOjE3MjQ2ODA0NTd9.JeD_HgzZ7CIGi2fkcK4zcix_62TwTCY2-1xJ-gW_hkE";
      var parsedTok = await ParseToken(tok);
      if (parsedTok !== null) {
        return parsedTok;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error get ifo Token ", error);
    }
  };

  const toPdfArray = async () => {
    // console.log("FIles ARRAY11111 ", fileArray);
    var answPdf = await ToPdf(fileArray, NameFile);
    if (answPdf) {
      var answB64 = await toB64(answPdf);
      if (answB64) {
        // console.log("FIles ARRAYpdf b64 ", answB64);
        var token = await info();
        await sendToServ(answPdf, answB64, token);
        setFileCounter(0);
        setFileArray([]);
        if (NameFile !== "Документ") {
          setNameFile("Документ");
        }
        setFormat("JPEG");
        setIsCheckedPages(false);
        setSaveScannBtn(false);
      }
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    event.preventDefault();
    Scann();
  };

  var valueSelect = (e) => {
    setFormat(e);
  };

  var resetCheckedPages = (event) => {
    setIsCheckedPages(event);
  };

  var AfterDefaultErrorSession = async () => {
    await Scann(false);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Багатосторінковий варіант сканування доступний лише для формату PDF.
    </Tooltip>
  );

  const Scann = async (CheckedScann) => {
    setShowWait(true);
    var scannDocument = await GetScan(
      CheckedScann === false ? CheckedScann : isCheckedScann
    );
    // console.log("Scann document ", scannDocument);
    if (scannDocument instanceof Blob) {
      if (format === "JPEG") {
        var jpgFile = await ToJpeg(scannDocument, NameFile);
        // console.log("Scann document jpg ", jpgFile);
        if (jpgFile) {
          var b64File = await toB64(jpgFile);
          var token = await info();
          // console.log(
          //   "Sending============================================================"
          // );
          await sendToServ(jpgFile, b64File, token);
          // console.log("Scann document base64 jpg", b64File);
          if (NameFile !== "Документ") {
            setNameFile("Документ");
          }
          setFormat("JPEG");
        }
      } else if (format === "PDF" && !isCheckedPages) {
        var pdfFile = await ToPdf(scannDocument, NameFile);
        // console.log("Scann document pdf ", pdfFile);
        if (pdfFile) {
          var b64File = await toB64(pdfFile);
          var token = await info();
          await sendToServ(pdfFile, b64File, token);
          // console.log("Scann document base64 pdf", b64File);
          if (NameFile !== "Документ") {
            setNameFile("Документ");
          }
          setFormat("JPEG");
        }
      } else if (format === "PDF" && isCheckedPages) {
        setFileArray((prevArray) => [...prevArray, scannDocument]);
        setFileCounter((prevCount) => prevCount + 1);
        // console.log("FIles ARRAY ", fileArray);
        // console.log("FIles COUNTER ", fileCounter);
      }
      setShowWait(false);
    } else if (
      scannDocument &&
      scannDocument.status &&
      scannDocument.status === 201
    ) {
      setIsCheckedScann(false);
      // console.log("Scann document default session ELSE 201 ", scannDocument);
      setShowWait(false);
      setShowDefaultError(true);
    } else if (
      scannDocument &&
      scannDocument.status &&
      scannDocument.status === 202
    ) {
      // console.log("Scann document selected session ELSE 202 ", scannDocument);
      setShowWait(false);
      setShowSelectError(true);
    } else if (
      scannDocument &&
      scannDocument.status &&
      scannDocument.status === 202
    ) {
      // console.log("Scann Uknown problem (error) ELSE 203 ", scannDocument);
      setShowWait(false);
      setShowUknownError(true);
    } else if (
      scannDocument &&
      scannDocument.status &&
      scannDocument.status === 404
    ) {
      // console.log("Scann Default scanner not found ELSE 404 ", scannDocument);
      setShowWait(false);
      setShowScanner404Error(true);
    } else if (
      scannDocument &&
      scannDocument.status &&
      scannDocument.status === 204
    ) {
      console.log(
        "Виникла помилка драйверу сканера! Повторіть спробу сканування!",
        scannDocument
      );
      setShowWait(false);
    } else {
      console.log(
        "Виникла помилка в програмі, зверніться до розробника! ",
        scannDocument
      );
      setShowWait(false);
    }
  };

  const handleReload = () => {
    setClickCount((prevCount) => prevCount + 1);

    // Якщо це перший клік, запускаємо таймер на 2 секунди
    if (clickCount === 0) {
      setTimer(
        setTimeout(() => {
          setClickCount(0); // Обнуляємо лічильник після 2 секунд
          setTimer(null);
        }, 2000)
      );
    }

    // Якщо кількість кліків дорівнює 5 протягом 2 секунд, виконуємо запит
    if (clickCount + 1 === 5) {
      Reload();
      setClickCount(0); // Обнуляємо лічильник
      clearTimeout(timer); // Скидаємо таймер
      setTimer(null);
    }
  };

  return (
    <>
      {EnterAccess ? (
        <div className="App">
          <DownloadModal
            show={showLoad}
            onClose={() => setShowLoad(false)}
            reload={handleReload}
          />
          <WaitModal show={showWait} onClose={() => setShowWait(false)} />
          <DefaultErrorModal
            show={showDefaultError}
            onClose={() => setShowDefaultError(false)}
            scanFunction={AfterDefaultErrorSession}
          />
          <SelectErrorModal
            show={showSelectError}
            onClose={() => setShowSelectError(false)}
          />
          <UknownErrorModal
            show={showUknownError}
            onClose={() => setShowUknownError(false)}
          />
          <ErrorScanner404Modal
            show={showScanner404Error}
            onClose={() => setShowScanner404Error(false)}
          />
          <div className="wrapper">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              className="formScann"
            >
              <div className="inputSelectDiv">
                <Form.Group controlId="validationCustom01" className="nameFile">
                  <Form.Label className="ms-2 no-select">
                    Назва файлу
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Введіть назву"
                    value={NameFile}
                    onChange={(e) => setNameFile(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Необхідно вказати назву файлу!
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="nameFile">
                  <Form.Label className="ms-2 no-select" onClick={handleReload}>
                    Формат файлу
                  </Form.Label>
                  <Form.Select
                    value={format}
                    onChange={(e) => valueSelect(e.target.value)}
                    required
                  >
                    <option value="JPEG">Зберегти як JPEG</option>
                    <option value="PDF">Зберегти як PDF</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Необхідно вказати формат файлу!
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <Form.Group className="CheckBoxGroup">
                <Form.Check
                  inline
                  label="Використовувати сканер за завмовчуванням"
                  name="scannCheck"
                  type="checkbox"
                  checked={isCheckedScann}
                  onChange={(e) => setIsCheckedScann(e.target.checked)}
                />
                {format !== "PDF" ? (
                  <OverlayTrigger
                    placement="right"
                    overlay={renderTooltip}
                    delay={{ show: 250, hide: 400 }}
                  >
                    <span className="d-inline-block spanWrapp">
                      <Form.Check
                        disabled
                        inline
                        label="Багатосторінковий файл"
                        name="pagesCheck"
                        type="checkbox"
                        checked={false}
                        style={{ pointerEvents: "none" }}
                      />
                    </span>
                  </OverlayTrigger>
                ) : (
                  <Form.Check
                    inline
                    label="Багатосторінковий файл"
                    name="pagesCheck"
                    type="checkbox"
                    checked={isCheckedPages}
                    onChange={(e) => resetCheckedPages(e.target.checked)}
                  />
                )}
              </Form.Group>
              <span
                className={
                  format === "PDF" && isCheckedPages
                    ? "filesCounter"
                    : "filesCounter1"
                }
              >
                Кількість відсканованих файлів: {fileCounter}
              </span>
              {fileArray.length > 0 ? (
                <div className="btnWrappNext">
                  <Button
                    className="nextBtn"
                    variant="success"
                    type="submit"
                    disabled={showWait}
                  >
                    Наступна
                  </Button>
                  <Button
                    className="saveBtn"
                    disabled={showWait}
                    variant="success"
                    onClick={() => {
                      setSaveScannBtn(true);
                    }}
                  >
                    Зберегти
                  </Button>
                </div>
              ) : (
                <Button
                  variant="success"
                  type="submit"
                  className="scannBtn"
                  disabled={showWait}
                >
                  Сканувати
                </Button>
              )}

              {/* <Button variant="outline-secondary">Скасувати</Button> */}
            </Form>
          </div>
        </div>
      ) : (
        <Access />
      )}
    </>
  );
}

export default App;
