import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Css/ErrorDefaultSession.css";
import Query from "../Components/Query";

function ErrorUknownModal({ show, onClose }) {
  const { serviceOnline, Reload } = Query();

  var resetPageAndServ = async () => {
    var reloadAnsw = await Reload();
    if (reloadAnsw === true) {
      setTimeout(async () => {
        var onlineAnsw = await serviceOnline();
        if (onlineAnsw === true) {
          onClose();
          window.location.reload();
        }
      }, 3000);
    }
  };
  return (
    <div className="ModalErrorWrap">
      <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
        <Modal.Header className="modalHeader">
          <Modal.Title className="ModalTitle">Повідомлення!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBodyError">
          <span>
            Scanner online не запущено або сервер перестав відповідати на
            запити. Натисніть "ОК" для перезавантаження! Якщо проблема не
            вирішиться, зверніться до розробника!
          </span>
        </Modal.Body>
        <Modal.Footer className="modalFotterError">
          <Button onClick={resetPageAndServ} className="OKbtn">
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ErrorUknownModal;
