import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Css/WaitingComplete.css";
import Loading from "../imagesProj/Loading.gif";

function WaitCompleteScann({ show, onClose }) {
  return (
    <div className="ModalWrap">
      <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
        <Modal.Header className="modalHeader">
          <Modal.Title className="ModalTitle">Scanner Online</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          <img
            width={50}
            height={50}
            src={Loading}
            alt="Loading"
            className="imgLoading"
          />
          <span className="titleImgLoading">
            Очікуйте! Відбувається процес сканування...
          </span>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default WaitCompleteScann;
